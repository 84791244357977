.chip {
  height: var(--size-14);
/* width: 168rem;*/
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: var(--lightgrey);
  border-radius: 10rem;
  padding: 0 var(--size-5);
}

.chip.stretch {
  width:100%;
}
.chip .screw {
  width: 10rem;
  height: 10rem;
  position: absolute;
  background-color: var(--darkgrey);
  mask: url('./icons/plus.svg');
  mask-size: cover;
}

.chip .screw.top-left {
  top: 10%;
  left: 3%;
}
.chip .screw.top-right {
  top: 10%;
  right: 3%;
}
.chip .screw.bottom-left {
  bottom: 10%;
  left: 3%;
}
.chip .screw.bottom-right {
  bottom: 10%;
  right: 3%;
}
