button {
  font-family: 'Liberator', monospace;
  font-size: var(--type-2);
  line-height: 0.8em;
  border: none;
  cursor: pointer;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

button.tooltip {
  overflow: visible;
}

button.tooltip:hover .tooltiptext {
  overflow:visible;
  display:block;
}

button.tooltip .tooltiptext {
    top: 40rem;
    display: none;
    background-color: inherit;
    padding: var(--size-1);
    position: absolute;
    max-width:250%;
    width:max-content;
    text-align: center;
    z-index: 9999;
    font-size: var(--type-0);
    line-height: calc(var(--type-0) * 0.8);
    border-radius: calc(calc(var(--type-0) + calc(var(--size-4) * 2)) / 8);
}

button.pickable:hover {
  transform: scale(1.1) rotate(2deg);
  z-index:9999;
}
button.pickable {
 transition: transform .2s ease-in-out; 
  cursor: grab;
}


button.disabled {
  opacity: 0.2;
  cursor: not-allowed;
  pointer-events: none;
}

button > span.icon {
  display: block;
  width: 1.2em;
  height: 1.2em;
}

button > span.text {
  display: block;
  width: 100%;
  height: 100%;
  filter: drop-shadow(0 0 0.2em rgba(255, 255, 255, 0.75));
}

button.huge {
  font-size: var(--type-4);
  line-height: calc(var(--type-4) * 0.8);
  border-radius: calc(calc(var(--type-4) + calc(var(--size-4) * 2)) / 8);
}
button.large {
  font-size: var(--type-3);
  line-height: calc(var(--type-3) * 0.8);
  border-radius: calc(calc(var(--type-3) + calc(var(--size-4) * 2)) / 8);
}
button.big {
  font-size: var(--type-2);
  line-height: calc(var(--type-2) * 0.8);
  border-radius: calc(calc(var(--type-2) + calc(var(--size-4) * 2)) / 8);
}
button.medium {
  font-size: var(--type-1);
  line-height: calc(var(--type-1) * 0.8);
  border-radius: calc(calc(var(--type-1) + calc(var(--size-4) * 2)) / 8);
}

button.small {
  font-size: var(--type-0);
  line-height: calc(var(--type-0) * 0.8);
  border-radius: calc(calc(var(--type-0) + calc(var(--size-4) * 2)) / 8);
}

button.tight {
  padding: var(--size-3) var(--size-4);
}
button.fullwidth {
  width: 100%;
  padding: var(--size-4) var(--size-3);
}
button.icon-aligned{
  width: 70%;
  height: var(--size-9);
  padding: var(--size-2) var(--size-3);
  margin-right:var(--size-4);
}
button.square {
  padding: var(--size-2) var(--size-2);
}
button.circle,
button.square {
  padding: 0;
}

button.tight > span.icon,
button.fullwidth > span.icon {
  background-color: var(--black50);
  border-radius: calc(calc(1.5em + calc(var(--size-1) * 2)) / 8);
  padding: var(--size-1);
}

button.tight > span.icon.no-background,
button.fullwidth > span.icon.no-background{
  background-color: transparent;
}

button.circle > span.icon {
  margin: 0;
  width: 50%;
  height: 50%;
}
button.square > span.icon {
  margin: 0;
  width: 60%;
  height: 60%;
}

button.circle.huge,
button.square.huge {
  width: var(--size-15);
  height: var(--size-15);
}
button.circle.large,
button.square.large {
  width: var(--size-14);
  height: var(--size-14);
}
button.circle.big,
button.square.big {
  width: var(--size-12);
  height: var(--size-12);
}
button.circle.medium,
button.square.medium {
  width: var(--size-9);
  height: var(--size-9);
}
button.circle.small,
button.square.small {
  width: var(--size-5);
  height: var(--size-5);
}

button.circle {
  border-radius: 100%;
}
button.square {
  border-radius: calc(calc(1.5em + calc(var(--size-1) * 2)) / 4);
}

button.has-icon {
  padding-left: calc(1.2em + calc(var(--size-2) * 2));
}

button.has-icon > span.icon {
  position: absolute;
  left: var(--size-2);
  top: 47.5%;
  transform: translateY(-50%);
}

button.has-icon > span.icon.large {
  position: absolute;
  left: var(--size-2);
  top: 47.5%;
  background-color: var(--purple);
  height: var(--size-10);
  width: var(--size-10);
  transform: translateY(-50%);
}
button.has-icon > span.icon.large.right {
  position: absolute;
  right: var(--size-2);
  top: 47.5%;
  background-color: var(--purple);
  height: var(--size-10);
  width: var(--size-10);
  transform: translateY(-50%);
  left:auto;
}
button.has-icon  span.text.right {
  right: 29.5%;
}
button.margin {
  margin-right: var(--size-2)
}
/* button:hover {
  box-shadow: 0 0 0 0.25em var(--white);
} */
button.selected {
  box-shadow: 0 0 0 0.25em var(--white);
}
