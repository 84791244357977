div[class^=grid-] {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
}

.grid-12 div[class^=column-] {
  padding: 0 var(--gutter-12);
}
.grid-12 .column-1 {
  width: calc(100% / 12);
}
.grid-12 .column-2 {
  width: calc(100% / 6);
}
.grid-12 .column-3 {
  width: calc(100% / 4);
}
.grid-12 .column-4 {
  width: calc(100% / 3);
}
.grid-12 .column-5 {
  width: calc(100% / calc(12 / 5));
}
.grid-12 .column-6 {
  width: calc(100% / 2);
}
.grid-12 .column-7 {
  width: calc(100% / calc(12 / 7));
}
.grid-12 .column-8 {
  width: calc(100% / calc(12 / 8));
}
.grid-12 .column-9 {
  width: calc(100% / calc(12 / 9));
}
.grid-12 .column-10 {
  width: calc(100% / calc(12 / 10));
}
.grid-12 .column-11 {
  width: calc(100% / calc(12 / 11));
}
.grid-12 .column-12 {
  width: 100%;
}

.grid-8 div[class^=column-] {
  padding: 0 var(--gutter-8);
}
.grid-8 .column-1 {
  width: calc(100% / 8);
}
.grid-8 .column-2 {
  width: calc(100% / 4);
}
.grid-8 .column-3 {
  width: calc(100% / calc(8 / 3));
}
.grid-8 .column-4 {
  width: calc(100% / 2);
}
.grid-8 .column-5 {
  width: calc(100% / calc(8 / 5));
}
.grid-8 .column-6 {
  width: calc(100% / calc(8 / 6));
}
.grid-8 .column-7 {
  width: calc(100% / calc(8 / 7));
}
.grid-8 .column-8 {
  width: 100%;
}
