.panel {
  border-radius: 10rem;
  border: solid var(--size-1);
  padding: var(--size-4);
  background: var(--darkgrey);
  overflow: hidden;
}

.panel.no-padding {
  padding: 0;
}

.panel-green {
  border-color: var(--green);
}
.panel-orange {
  border-color: var(--orange);
}
.panel-red {
  border-color: var(--red);
}
.panel-pink {
  border-color: var(--pink);
}
.panel-purple {
  border-color: var(--purple);
}
.panel-blue {
  border-color: var(--blue);
}
.panel-lightgrey {
  border-color: var(--lightgrey);
}
.panel-mediumgrey {
  border-color: var(--mediumgrey);
}
.panel-darkgrey {
  border-color: var(--darkgrey);
}
.panel-white {
  border-color: var(--white);
}

.panel .list {
  display: flex;
  flex-direction: column;
}

.panel .list > * { margin: var(--size-2) 0; }
.panel .list > :first-child { margin-top: 0; }
.panel .list > :last-child { margin-bottom: 0; }
