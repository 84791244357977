:root {
  --green: #00AE3C;
  --orange: #FF7420;
  --red: #FF2A36;
  --pink: #F84BF7;
  --purple: #9F44FB;
  --blue: #0F6DEB;
  --white: #FFFFFF;
  --black: #000000;
  --lightgrey: #54556C;
  --mediumgrey: #2F3043;
  --darkgrey: #222338;
  --black50: rgba(0, 0, 0, 0.5);
}

.green {
  background: var(--green);
  color: var(--white);
}
.orange {
  background: var(--orange);
  color: var(--white);
}
.red {
  background: var(--red);
  color: var(--white);
}
.pink {
  background: var(--pink);
  color: var(--white);
}
.purple {
  background: var(--purple);
  color: var(--white);
}
.blue {
  background: var(--blue);
  color: var(--white);
}
.white {
  background: var(--white);
  color: var(--darkgrey);
}
.black {
  background: var(--black);
  color: var(--white);
}
.lightgrey {
  background: var(--lightgrey);
  color: var(--white);
}
.mediumgrey {
  background: var(--mediumgrey);
  color: var(--white);
}
.darkgrey {
  background: var(--darkgrey);
  color: var(--white);
}
.black50 {
  background: var(--black50);
  color: var(--white);
}
