.select-sprite {
  cursor: grab;
  position: absolute;
}

.select-sprite .glitches {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translate(-50%, 0%);
  padding: var(--size-2) 0;
  width: calc(var(--size-8) * 3);
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.select-sprite .glitches > * { margin: var(--size-1) }
.select-sprite .glitches > *:first-child { margin-left: 0 }
.select-sprite .glitches > *:last-child { margin-right: 0 }

.select-sprite .move-handle {
  background: rgba(159, 68, 251, 0.35);
  width: 100%;
  height: 100%;
}

.select-sprite .rotation-handle,
.select-sprite .upper-handle,
.select-sprite .upper-right-handle,
.select-sprite .right-handle,
.select-sprite .bottom-right-handle,
.select-sprite .bottom-handle,
.select-sprite .bottom-left-handle,
.select-sprite .left-handle,
.select-sprite .upper-left-handle {
  position: absolute;
  border-radius: calc(var(--size-1) / 2);
  height: var(--size-1);
  width: var(--size-3);
  background: var(--purple);
}

.select-sprite .upper-handle {
  top: calc(var(--size-1) / -2);
  left: 50%;
  transform: translate(calc(var(--size-3) / -2), 0);
  cursor: row-resize;
}
.select-sprite .bottom-handle {
  top: calc(100% - calc(var(--size-1) / 2));
  left: 50%;
  transform: translate(-50%, 0);
  cursor: row-resize;
}
.select-sprite .right-handle {
  top: 50%;
  left: 100%;
  transform: translate(-50%, 0) rotate(90deg);
  cursor: col-resize;
}
.select-sprite .left-handle {
  top: 50%;
  left: 0%;
  transform: translate(-50%, 0) rotate(90deg);
  cursor: col-resize;
}

.select-sprite .upper-right-handle {
  top: calc(var(--size-1) / -2);
  right: calc(var(--size-1) / -2);
  width: var(--size-2);
  height: var(--size-2);
  background: 0;
  border-top: solid var(--size-1) var(--purple);
  border-right: solid var(--size-1) var(--purple);
  cursor: nesw-resize;
}
.select-sprite .upper-left-handle {
  top: calc(var(--size-1) / -2);
  left: calc(var(--size-1) / -2);
  width: var(--size-2);
  height: var(--size-2);
  background: 0;
  border-top: solid var(--size-1) var(--purple);
  border-left: solid var(--size-1) var(--purple);
  cursor: nwse-resize;
}
.select-sprite .bottom-right-handle {
  top: calc(calc(100% + calc(var(--size-1) / 2)) - var(--size-2));
  right: calc(var(--size-1) / -2);
  width: var(--size-2);
  height: var(--size-2);
  background: 0;
  border-bottom: solid var(--size-1) var(--purple);
  border-right: solid var(--size-1) var(--purple);
  cursor: nwse-resize;
}
.select-sprite .bottom-left-handle {
  top: calc(calc(100% + calc(var(--size-1) / 2)) - var(--size-2));
  left: calc(var(--size-1) / -2);
  width: var(--size-2);
  height: var(--size-2);
  background: 0;
  border-bottom: solid var(--size-1) var(--purple);
  border-left: solid var(--size-1) var(--purple);
  cursor: nesw-resize;
}

.select-sprite .rotation-handle {
  top: 50%;
  left: 100%;
  transform: translate(0, 0);
}

.select-sprite .rotation-handle::after {
  content: '';
  width: var(--size-2);
  height: var(--size-2);
  border-radius: var(--size-2);
  position: absolute;
  top: -50%;
  right: -25%;
  background: var(--purple);
}

.select-sprite.rotating .move-handle {
  border-radius: 100%;
  border: solid var(--size-1) var(--purple);
}

.select-sprite.resizing .move-handle {
  border: solid var(--size-1) var(--purple);
}

.select-sprite.rotating .rotation-handle {
  left: 100%;
}

.select-sprite.highlight {
  pointer-events:none;
}

.select-sprite.disabled {
  pointer-events:none;
}

.select-sprite.disbled > * {
  pointer-events: none;
}
