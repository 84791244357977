@font-face {
  font-family: 'Liberator';
  font-style: normal;
  font-weight: 900;
  src: local('Liberator'), url('/static/fonts/Liberator/Liberator-Heavy.woff2') format('woff2');
}

@font-face {
  font-family: 'Liberator';
  font-style: normal;
  font-weight: 500;
  src: local('Liberator'), url('/static/fonts/Liberator/Liberator-Medium.woff2') format('woff2');
}

@font-face {
  font-family: 'Liberator';
  font-style: normal;
  font-weight: 300;
  src: local('Liberator'), url('/static/fonts/Liberator/Liberator-Light.woff2') format('woff2');
}

:root {
  --type-0: 14rem;
  --type-1: 18rem;
  --type-2: 20rem;
  --type-3: 28rem;
  --type-4: 32rem;
}

body {
  font-family: 'Liberator', monospace;
  font-size: var(--type-1);
}

h1, .huge-letters {
  font-size: var(--type-4);
}
h2, .large-letters {
  font-size: var(--type-3);
}
h3, .big-letters {
  font-size: var(--type-2);
}
p, .medium-letters {
  font-size: var(--type-1);
}
label, .small-letters {
  font-size: var(--type-0);
}
