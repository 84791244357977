.toolbar {
  border-radius: 10rem;
  display: flex;
  flex-direction: column;
  padding: var(--size-3) var(--size-2);
  justify-content: center;
  align-items: center;
  background: var(--lightgrey);
}

.toolbar > * { margin: calc(var(--size-3) / 2) 0; }
.toolbar > :first-child { margin-top: 0; }
.toolbar > :last-child { margin-bottom: 0; }

.toolbar hr {
  border: solid 1rem var(--darkgrey);
  width: 100%;
}
