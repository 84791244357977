@keyframes wiggle {  
 0% { transform: rotate(0deg); }
 10% { transform: rotate(5deg); }
 20% { transform: rotate(-5deg); }
 30% { transform: rotate(0deg); }
}

.input.wiggle {
animation: wiggle 2.5s infinite;
}

.input.wiggle:hover {
animation: none;
}

.input {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.input.greyedOut {
  opacity: 0.2;
}
.input.disabled {
  pointer-events: none;
  opacity: 0.2;
}
.input label,
.input .input-area {
  width: 100%;
  font-family: 'Liberator', monospace;
  color: var(--white);
}
.input label {
  margin-bottom: var(--size-3);
  font-size: var(--type-0);
  line-height: var(--type-0);
  padding: var(--size-0) 0;
}
.input .input-area {
  font-size: var(--type-0);
  line-height: var(--type-0);
  height: var(--size-8);
  padding: 0 var(--size-2);
  background: var(--black50);
  outline: none;
  border: none;
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.input-area.selected,
.input-area:focus {
  box-shadow: 0 0 0 var(--size-0) var(--white);
}

.input-option .input-area {
  padding: 0;
}
.input-option .input-area .option-control {
  cursor: pointer;
  display: block;
  padding: var(--size-2);
}

.input-sprite .input-area {
  justify-content: center;
  cursor: pointer;
}

.input-slider .input-area {
  justify-content: flex-end;
}

.input-slider input[type="range"] {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: ew-resize;
  margin: 0;
}

.input-slider .foreground {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
}

.panel-orange .input-slider .foreground {
  background: var(--orange);
}
.panel-green .input-slider .foreground {
  background: var(--green);
}
.panel-red .input-slider .foreground {
  background: var(--red);
}
.panel-pink .input-slider .foreground {
  background: var(--pink);
}
.panel-purple .input-slider .foreground {
  background: var(--purple);
}
.panel-blue .input-slider .foreground {
  background: var(--blue);
}
.panel-lightgrey .input-slider .foreground {
  background: var(--lightgrey);
}
.panel-mediumgrey .input-slider .foreground {
  background: var(--mediumgrey);
}
.panel-darkgrey .input-slider .foreground {
  background: var(--darkgrey);
}

.input-slider .value {
  font-family: 'Liberator', monospace;
  color: var(--white);
  background-color: transparent;
  outline:none;
  box-shadow: none;
  border: 0;
  font-size: var(--type-0);
  line-height: var(--type-0);
  text-align: right;    
}

.input-slider .value::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.input-slider input[type="range"]:focus {
  outline: none;
}

.input-slider input[type="range"]::-webkit-slider-runnable-track {
  width: 100%;
  cursor: pointer;
  border: none;
}
.input-slider input[type="range"]::-moz-range-track {
  cursor: pointer;
  border-radius: 0px;
  border: none;
}
.input-slider input[type="range"]::-ms-track {
  cursor: pointer;
  border-radius: 0px;
  border: none;
}

.input-slider input[type="range"]::-webkit-slider-thumb {
  height: 1px;
  width: 1px;
}
.input-slider input[type="range"]::-moz-range-thumb {
  height: 1px;
  width: 1px;
}
.input-slider input[type="range"]::-ms-thumb {
  height: 1px;
  width: 1px;
}
