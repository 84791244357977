i.icon {
  display: block;
  width: 100%;
  height: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
}
i.icon-forward {
  background-image: url('icons/forward.svg');
}
i.icon-follow {
  background-image: url('icons/follow.svg');
}

i.icon-mute {
  background-image: url('icons/mute.svg');
}

i.icon-unmute {
  background-image: url('icons/unmute.svg');
}

i.icon-finish-line {
  background-image: url('icons/finish line.svg');
}

i.icon-throw {
  background-image: url('icons/throw.svg');
}

i.icon-timewarp {
  background-image: url('icons/timewarp.svg');
}

i.icon-blackhole {
  background-image: url('icons/blackhole.svg');
}

i.icon-repel {
  background-image: url('icons/repel.svg');
}

i.icon-musicify {
  background-image: url('icons/musicify.svg');
}

i.icon-sticky {
  background-image: url('icons/sticky.svg');
}

i.icon-blackhole {
  background-image: url('icons/blackhole.svg');
}

i.icon-toss {
  background-image: url('icons/toss.svg');
}

i.icon-toss {
  background-image: url('icons/toss.svg');
}

i.icon-strobe {
  background-image: url('icons/strobe.svg');
}

i.icon-horizontalarrows {
  background-image: url('icons/horizontalarrows.svg');
}

i.icon-pencil {
  background-image: url('icons/pencil.svg');
}

i.icon-play-level {
  background-image: url('icons/play-level.svg');
}

i.icon-drawer-out {
  background-image: url('icons/drawer-out.svg');
}

i.icon-drawer-in {
  background-image: url('icons/drawer-in.svg');
}

i.icon-shield {
  background-image: url('icons/shield.svg');
}

i.icon-rocket {
  background-image: url('icons/rocket.svg');
}

i.icon-ladder {
  background-image: url('icons/ladder.svg');
}

i.icon-trampoline {
  background-image: url('icons/trampoline.svg');
}

i.icon-wander {
  background-image: url('icons/wander.svg');
}

i.icon-paint {
  background-image: url('icons/paint.svg');
}

i.icon-golf {
  background-image: url('icons/golf.svg');
}

i.icon-division {
  background-image: url('icons/division.svg');
}

i.icon-cross {
  background-image: url('icons/cross.svg');
}

i.icon-bouncify {
  background-image: url('icons/bouncify.svg');
}

i.icon-tombstone {
  background-image: url('icons/tombstone.svg');
}

i.icon-trophy {
  background-image: url('icons/trophy.svg');
}

i.icon-pushpin {
  background-image: url('icons/pushpin.svg');
}

i.icon-refresh {
  background-image: url('icons/refresh.svg');
}

i.icon-compass {
  background-image: url('icons/compass.svg');
}

i.icon-teleport {
  background-image: url('icons/teleport.svg');
}

i.icon-traffic-light {
  background-image: url('icons/traffic_light.svg');
}

i.icon-jump {
  background-image: url('icons/jump.svg');
}

i.icon-glow {
  background-image: url('icons/glow.svg');
}

i.icon-slippery {
  background-image: url('icons/slippery.svg');
}

i.icon-screenwrap {
  background-image: url('icons/screenwrap.svg');
}

i.icon-pulse {
  background-image: url('icons/pulse.svg');
}

i.icon-helicopter {
  background-image: url('icons/helicopter.svg');
}

i.icon-dotgrid {
  background-image: url('icons/dot_grid.svg');
}

i.icon-headstand {
  background-image: url('icons/headstand.svg');
}

i.icon-moonwalk {
  background-image: url('icons/moonwalk.svg');
}

i.icon-patrol {
  background-image: url('icons/patrol.svg');
}

i.icon-frontflip {
  background-image: url('icons/frontflip.svg');
}

i.icon-flippify {
  background-image: url('icons/flippify.svg');
}

i.icon-hourglass {
  background-image: url('icons/hour-glass.svg');
}

i.icon-fireworks {
  background-image: url('icons/fireworks.svg');
}

i.icon-verticalarrows {
  background-image: url('icons/vertical-arrows.svg');
}

i.icon-confetti {
  background-image: url('icons/confetti.svg');
}

i.icon-dizzy {
  background-image: url('icons/dizzy.svg');
}

i.icon-balloon {
  background-image: url('icons/balloon.svg');
}

i.icon-chase {
  background-image: url('icons/chase.svg');
}

i.icon-digify {
  background-image: url('icons/digify.svg');
}

i.icon-cartwheel {
  background-image: url('icons/cartwheel.svg');
}

i.icon-appledown {
  background-image: url('icons/appledown.svg');
}

i.icon-applesonardown {
  background-image: url('icons/applesonardown.svg');
}

i.icon-arrowdown {
  background-image: url('icons/arrowdown.svg');
}

i.icon-arrowup {
  background-image: url('icons/arrowup.svg');
}

i.icon-ball {
  background-image: url('icons/ball.svg');
}

i.icon-bomb {
  background-image: url('icons/bomb.svg');
}

i.icon-boulder {
  background-image: url('icons/boulder.svg');
}

i.icon-button {
  background-image: url('icons/button.svg');
}

i.icon-circlegrid {
  background-image: url('icons/circlegrid.svg');
}

i.icon-clone {
  background-image: url('icons/clone.svg');
}

i.icon-dots {
  background-image: url('icons/dots.svg');
}

i.icon-doublespiral {
  background-image: url('icons/doublespiral.svg');
}

i.icon-droplet {
  background-image: url('icons/droplet.svg');
}

i.icon-duplicate {
  background-image: url('icons/duplicate.svg');
}

i.icon-elephant {
  background-image: url('icons/elephant.svg');
}

i.icon-expand {
  background-image: url('icons/expand.svg');
}

i.icon-eye {
  background-image: url('icons/eye.svg');
}

i.icon-fastforward {
  background-image: url('icons/fastforward.svg');
}

i.icon-fireball {
  background-image: url('icons/fireball.svg');
}

i.icon-gamepad {
  background-image: url('icons/gamepad.svg');
}

i.icon-ghost {
  background-image: url('icons/ghost.svg');
}

i.icon-hamburger {
  background-image: url('icons/hamburger.svg');
}

i.icon-hand {
  background-image: url('icons/hand.svg');
}

i.icon-hurricane {
  background-image: url('icons/hurricane.svg');
}

i.icon-icecube {
  background-image: url('icons/icecube.svg');
}

i.icon-invisible {
  background-image: url('icons/invisible.svg');
}

i.icon-isometric {
  background-image: url('icons/isometric.svg');
}

i.icon-jetpack {
  background-image: url('icons/jetpack.svg');
}

i.icon-lightning {
  background-image: url('icons/lightning.svg');
}

i.icon-loop {
  background-image: url('icons/loop.svg');
}

i.icon-magnet {
  background-image: url('icons/magnet.svg');
}

i.icon-mouse {
  background-image: url('icons/mouse.svg');
}

i.icon-notes {
  background-image: url('icons/notes.svg');
}

i.icon-pacman {
  background-image: url('icons/pacman.svg');
}

i.icon-pause {
  background-image: url('icons/pause.svg');
}

i.icon-pen {
  background-image: url('icons/pen.svg');
}

i.icon-photocamera {
  background-image: url('icons/photocamera.svg');
}

i.icon-piano {
  background-image: url('icons/piano.svg');
}

i.icon-play {
  background-image: url('icons/play.svg');
}

i.icon-plus {
  background-image: url('icons/plus.svg');
}

i.icon-portal {
  background-image: url('icons/portal.svg');
}

i.icon-question {
  background-image: url('icons/question.svg');
}

i.icon-resize {
  background-image: url('icons/resize.svg');
}

i.icon-rewind {
  background-image: url('icons/rewind.svg');
}

i.icon-shuffle {
  background-image: url('icons/shuffle.svg');
}

i.icon-snail {
  background-image: url('icons/snail.svg');
}

i.icon-snowflake {
  background-image: url('icons/snowflake.svg');
}

i.icon-speaker {
  background-image: url('icons/speaker.svg');
}

i.icon-spiral {
  background-image: url('icons/spiral.svg');
}

i.icon-stack {
  background-image: url('icons/stack.svg');
}

i.icon-star {
  background-image: url('icons/star.svg');
}

i.icon-sun {
  background-image: url('icons/sun.svg');
}

i.icon-swapcharacter {
  background-image: url('icons/swapcharacter.svg');
}

i.icon-swapkeys {
  background-image: url('icons/swapkeys.svg');
}

i.icon-swim {
  background-image: url('icons/swim.svg');
}

i.icon-target {
  background-image: url('icons/target.svg');
}

i.icon-tennisball {
  background-image: url('icons/tennisball.svg');
}

i.icon-trash {
  background-image: url('icons/trash.svg');
}

i.icon-unicorn {
  background-image: url('icons/unicorn.svg');
}

i.icon-videocamera {
  background-image: url('icons/videocamera.svg');
}

i.icon-vulcan {
  background-image: url('icons/vulcan.svg');
}

i.icon-wand {
  background-image: url('icons/wand.svg');
}

i.icon-wink {
  background-image: url('icons/wink.svg');
}

i.icon-zombie {
  background-image: url('icons/zombie.svg');
}