:root {
  --size-0: 2rem;
  --size-1: 4rem;
  --size-2: 8rem;
  --size-3: 12rem;
  --size-4: 16rem;
  --size-5: 20rem;
  --size-6: 24rem;
  --size-7: 28rem;
  --size-8: 32rem;
  --size-9: 36rem;
  --size-10: 40rem;
  --size-11: 44rem;
  --size-12: 48rem;
  --size-13: 52rem;
  --size-14: 56rem;
  --size-15: 60rem;

  --gutter-12: 15rem;
  --gutter-8: 13.5rem;
}
