* {
  box-sizing: border-box;
  position: relative;
  -webkit-font-smoothing: antialiased;
}

:root {
  font-size: 0.1vw;
}

body {
  font-size: 100%;
  background-color: var(--black);
  color: var(--white);
  font-size: 18rem;
  display: flex;
  width: 100vw;
  height: 100vh;
}

#app {
  margin: auto;
  max-width:1002rem;
}

#app_ {
  width: calc(calc(16 / 10) * 100vh);
}

@media (min-aspect-ratio: 3/2) {
  :root {
    font-size: calc(calc(5 / 3) * 0.1vh);
  }
  #app_ {
    width: calc(calc(5 / 3) * 100vh);
  }
}
