.gamescreen {
  position: relative;
  border: solid var(--lightgrey) var(--size-4);
  border-radius: var(--size-5);
  width: 100%;
  overflow: hidden;
}

.gamescreen > .game {
  display: block;
  width: 100%;
  margin: 0;
  padding: 0;
  transform: scale(1.01);
  cursor: pointer;
}
