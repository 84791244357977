.carousel {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.carousel .info {
  flex-grow: 1;
}

.carousel .info > * { margin: var(--size-2) 0; }
.carousel .info > :first-child { margin-top: 0; }
.carousel .info > :last-child { margin-bottom: 0; }

.carousel .info img {
  width: 100%;
  height: 134rem;
  border-radius: var(--size-2);
  object-fit: cover;
}

.carousel .info p {
  height: 72rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: var(--type-1);
}

.carousel .info.active {
  display: flex;
  flex-direction: column;
}

.carousel .navigation {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: var(--size-4);
}

.carousel .navigation .navigation-item {
  width: var(--size-3);
  height: var(--size-3);
  background: var(--lightgrey);
  margin: 0 var(--size-1);
  cursor: pointer;
}

.carousel .navigation .navigation-item.active {
  background: var(--white);
}
