#wrapper {
  width:100%;
  margin:auto;
}
#game-title {
  width:100%;
  font-size: var(--type-1);
  line-height: var(--type-1);
  font-weight: 500;
  margin: 0;
  padding: 0;
  text-align: center;
  margin-bottom: var(--size-4);
  
}
.branding {
  display:none;
  float:left;
  width:124rem;
}

.branding img {
  width:100%;
}

#glitch-editor-iframe{
  position:absolute;
  width:100%;
  height:100%;
  z-index:99999;
}

#project-display-wrapper{
  height:90vh;
  display:flex;
  flex-wrap: wrap;
  align-items: stretch;
  align-content: flex-start;
  flex-direction: column;

}

#project-display-wrapper > button{
  margin: var(--size-2);
}

.top-row {
  padding: var(--size-4) 0 var(--size-5) 0;
  text-align: center;
  justify-content: center;
}

.top-row .hamburger,


/* TODO: MOVE THIS TO CHIP COMPONENT SOON */
.chip {
  display: flex;
  flex-direction: row;
}
.chip > * {
  margin: 0 var(--size-1);
}

.grid-12.main-row {
  align-items: stretch
}

.main-row .menu {
  align-self: center;
}

.main-row .context-menu {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.context-menu .panel-large {
  height: 294rem;
}
.context-title {
  height:64rem;
}

.context-menu .panel-small {
  height: 176rem;
  display:flex;
  flex-direction: column;
}
.context-menu .panel-small .glitch-drop-areas-wrapper {
display:flex;
flex-direction: column;
height:100%;
align-content:space-between;
justify-content: space-between;
}
.context-menu .panel-tiny {
  height: auto;
}
.gamescreen {
  height: 485rem;
  display:flex;
  align-items: center;
}

.gamescreen.fullscreen {
  height: 552rem;
  }
  
.gamescreen .mainmenu .foreground{
  background-color:var(--purple);
}

.gamescreen .mainmenu {
  display:flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  height: 100%;
  width:100%;
 }

.gamescreen .mainmenu > * {
  width:50%;
}
.top-row .game-controls {
  display: flex;
  justify-content: center;
  align-items: center;
}

.fullscreen .gamescreen {
  border: solid var(--lightgrey) var(--size-2);
  border-radius: var(--size-2);
  overflow: hidden;

}

.inventory {
  height: 485rem;
  position: relative;
  border: solid var(--lightgrey) var(--size-4);
  border-radius: var(--size-5);
  width: 100%;
  background-color: var(--darkgrey);
  display: flex;
  flex-direction: column;
}

.inventory .inventory-header {
  height: calc(var(--size-8) + var(--size-10));
}

.inventory .inventory-header .categories,
.inventory .inventory-header .search {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-self: center;
}

.inventory .inventory-header .search .input-area {
  font-size: var(--type-1);
  line-height: var(--type-1);
  height: var(--size-10);
}
.inventory .inventory-header .search label {
  padding: 0;
  margin: 0;
}

.code-editor-panel {
  overflow-y: scroll;
  scrollbar-width: thin;
  scrollbar-color: var(--mediumgrey) transparent;
}
.code-editor-panel::-webkit-scrollbar {
  width: var(--size-2);
  background-color: transparent;
}

.code-editor-panel::-webkit-scrollbar-thumb {
  background: var(--mediumgrey);
}

.code-editor-panel .input {
  display:block;
}

.input {
  display: block;
}
.inventory .inventory-items {
  overflow-y: scroll;
  height: 80%;
  scrollbar-width: thin;
  scrollbar-color: var(--mediumgrey) transparent;
}
.inventory-items::-webkit-scrollbar {
  width: var(--size-2);
  background-color: transparent;
}

.inventory-items::-webkit-scrollbar-thumb {
  background: var(--mediumgrey);
}

.inventory .inventory-items .items {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.inventory .inventory-items .categories > * {
  width:90%;
  padding: var(--size-2) var(--size-4);
  margin-bottom:var(--size-3);
}
.inventory .inventory-items .categories{
  margin-top: var(--size-3)
}
.inventory .inventory-items .items > * {
  margin: calc(var(--gutter-8) - calc(var(--size-2) / 8));
}

.inventory .inventory-items .items > *:nth-child(6n) {
  margin-right: 0;
}
.inventory .inventory-items .items > *:nth-child(6n+1) {
  margin-left: 0;
}

.inventory .inventory-items .category {
  margin-top: calc(var(--gutter-8) - calc(var(--size-2) / 8));
}

.pack {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: var(--size-2);
}

.pack .header {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: var(--size-3) 0;
}

.pack .header .text {
  filter: drop-shadow(0 0 0.2em rgba(255, 255, 255, 0.75));
}

.pack .thumbnail {
  width: calc(30% + var(--gutter-8));
  padding-right: var(--gutter-8);
}
.pack .thumbnail img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.pack .glitches {
  width: calc(70% - var(--gutter-8));
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding-left: var(--gutter-8);
}
.pack .glitches > * {
  margin-top: var(--size-2);
  margin-bottom: var(--size-2);
  margin-left: calc(var(--gutter-8) - calc(var(--size-2) / 4));
  margin-right: calc(var(--gutter-8) - calc(var(--size-2) / 4));
}
.pack .glitches > *:nth-child(4n) {
  margin-right: 0;
}
.pack .glitches > *:nth-child(4n+1) {
  margin-left: 0;
}

#dragging-element {
  display: none;
  opacity: 1;
  position: absolute;
  z-index: 99;
  transition: all linear 0.03s;
  pointer-events: none;
}

#dragging-element.dragging {
  display: block;
  opacity: 0.3;
}

.code-stickers {
  position: absolute;
  width: calc(4 * var(--size-5));
  transform: translateX(-50%) translateY(var(--size-1));
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.code-stickers .sticker {
  padding: var(--size-0);
}

.logo {
  display:flex;
  align-items: flex-end;
  height:184rem;
}
.logo .logo-img{
    left:25%;
      transition: all .2s;
      transform: rotate(0deg) scale(1); 
    width:70%;
  }
  .logo .logo-img:hover {
    transform: rotate(-5deg) scale(1.2); 
  }

  .next-level-message {
    display:flex;
    flex-direction: column;
    justify-content: center;
    padding:10%;
    width:96%;
    height:100%;
    background-color: rgba(30, 30, 30, 0.8);
    position:absolute;
    align-items: center;
    flex-wrap: wrap;
    z-index: 999999;
  }

  .next-level-message > * {
    margin: var(--size-4);
  }

  .minimized-next-level-message {
    width:100%;
    height:3%;
    overflow: hidden;
    position: absolute;
    z-index: 999999;
    padding-left:63%;
    top: 97%;
    animation: 1s slide-down;
    transition: top ease .5s, opacity ease .5s, height ease .5s;
    opacity: .0;
  }

  .minimized-next-level-message:hover {
    top: 88%;
    height:12%;
    opacity:1;
  }

  


@keyframes slide-down {
  from {
    top: 77%;
    height:12%;
    opacity: 1;
  }
  to {
    top:99%;
    height: 1%;
    opacity:0;
  }
}